import React, { useContext } from "react";
import { Navbar } from "@mantine/core";
import { Logout, QuestionMark, News } from "tabler-icons-react";
import { stateContext } from "../context/FunctionContext";
import { Box, NavLink } from "@mantine/core";
import { NavLink as NavigateDom, useNavigate } from "react-router-dom";

//
const NavbarComponent = ({ setOpenNavbar, openNavbar }) => {
  const { setToken } = useContext(stateContext);
  const navigate = useNavigate();

  return (
    <Navbar
      hidden={!openNavbar}
      className="navbar"
      p="xs"
      width={{ base: 250 }}
    >
      <Box sx={{ width: 220 }}>
        <NavigateDom onClick={() => setOpenNavbar(false)} to="/">
          <NavLink label="Contact" icon={<News size={20} />} mb="xs" />
        </NavigateDom>

        <NavigateDom onClick={() => setOpenNavbar(false)} to="/news">
          <NavLink label="News" icon={<QuestionMark size={20} />} mb="xs" />
        </NavigateDom>

        <div
          onClick={() => {
            if (window.confirm("Are you sure about logout ?")) {
              setToken(null);
              localStorage.clear();
              navigate("/login");
            }
          }}
        >
          <NavLink label="Logout" icon={<Logout size={20} />} mb="xs" />
        </div>
      </Box>
    </Navbar>
  );
};

export default NavbarComponent;
