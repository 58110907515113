import { useContext } from "react";
import {
  AppShell,
  Navbar,
  Header,
  MediaQuery,
  Burger,
  MantineProvider,
} from "@mantine/core";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { stateContext } from "../context/FunctionContext";

import "./App.css";
//
import HeaderComponent from "../components/Header";
import NavbarComponent from "../components/Navbar";
import Login from "../pages/Login";
import ContactUs from "../pages/ContactUs";
import News from "../pages/News";
//

export default function AppShellComponent() {
  const { openNavbar, setOpenNavbar, isLoading, token, dark } =
    useContext(stateContext);

  return (
    <BrowserRouter basename="">
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          fontFamily: "IBM Plex Sans Arabic",
          colorScheme: dark ? "dark" : "light",
        }}
      >
        <AppShell
          navbarOffsetBreakpoint="md"
          navbar={
            token && (
              <Navbar
                p="md"
                hiddenBreakpoint="md"
                hidden={!openNavbar}
                width={{ sm: 250, lg: 250 }}
              >
                <NavbarComponent
                  openNavbar={openNavbar}
                  setOpenNavbar={setOpenNavbar}
                />
              </Navbar>
            )
          }
          header={
            token && (
              <Header height={100} p="xl">
                <div className="header">
                  <MediaQuery largerThan="md" styles={{ display: "none" }}>
                    <Burger
                      opened={openNavbar}
                      onClick={() => setOpenNavbar((o) => !o)}
                      size="md"
                      mr="xl"
                    />
                  </MediaQuery>
                  <HeaderComponent />
                </div>
              </Header>
            )
          }
          j
        >
          {isLoading ? <div className="loader"></div> : null}
          <Routes>
            <Route path="/">
              <Route
                index
                element={token ? <ContactUs /> : <Navigate to="/login" />}
              />

              <Route
                path="news"
                element={token ? <News /> : <Navigate to="/login" />}
              />

              <Route
                path="login"
                element={token ? <Navigate to="/" /> : <Login />}
              />

              <Route
                exact
                path="*"
                element={
                  <h2 style={{ textAlign: "center", margin: "5rem 0" }}>
                    Page not found ...
                  </h2>
                }
              />
            </Route>
          </Routes>
        </AppShell>
      </MantineProvider>
    </BrowserRouter>
  );
}
