import {
  Button,
  FileInput,
  Modal,
  Paper,
  Text,
  TextInput,
  Grid,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useAxiosInstance } from "../axios/useAxiosInstance";
import { useForm } from "@mantine/form";
import { FiUpload } from "react-icons/fi";
import { Trash, Edit2 } from "lucide-react";

import { useContext } from "react";
import { stateContext } from "../context/FunctionContext";

const News = () => {
  const axios = useAxiosInstance();
  const [showCreateAndUpdateNews, setShowCreateAndUpdateNews] = useState(null);
  const [updateNews, setUpdateNews] = useState(null);
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setIsLoading, URL_IMAGES } = useContext(stateContext);

  const form = useForm({
    initialValues: {
      title: "",
      ar_title: "",
      desc: "",
      ar_desc: "",
      file: "",
    },
  });

  // create news
  const handelSubmit = async (e) => {
    if (
      form.values.title === "" ||
      form.values.desc === "" ||
      form.values.ar_title === "" ||
      form.values.ar_desc === "" ||
      form.values.file === ""
    ) {
      return alert(" جميع الحقول مطلوبة");
    }
    const data = new FormData();
    data.append("title", form.values.title);
    data.append("ar_title", form.values.ar_title);
    data.append("desc", form.values.desc);
    data.append("ar_desc", form.values.ar_desc);
    data.append("imgNews", form.values.file);

    if (updateNews) {
      try {
        setLoading(true);
        const res = await axios.put("news/" + updateNews._id, data);
        if (res.data.error) {
          return alert(res.data.error);
        }
        setLoading(false);

        window.location.reload();
      } catch (error) {
        console.log(error.message);
        setLoading(false);
        setIsLoading(false);
      }
      return "";
    }
    try {
      if (
        form.values.title === "" ||
        form.values.ar_title === "" ||
        form.values.desc === "" ||
        form.values.ar_desc === "" ||
        form.values.file === ""
      ) {
        return alert(
          "شيءً ما خاطئ يرجى ادخال معلومات صحيحة ، جميع الحقول مطلوبة"
        );
      }
      setLoading(true);
      const res = await axios.post("news", data);
      if (res.data.error) {
        return alert(res.data.error);
      }
      setNews([...news, res.data].reverse());
      setShowCreateAndUpdateNews(false);
      form.values.title = "";
      form.values.ar_title = "";
      form.values.desc = "";
      form.values.ar_desc = "";
      form.values.file = "";
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await axios.get("news");
        if (res.status !== 200) {
          return alert(res.data.error);
        }
        setNews(res.data);
      } catch (error) {
        console.log(error.message);
        setIsLoading(false);
      }
    };
    getNews();
  }, []);

  const deleteSingleNews = async (id) => {
    if (window.confirm("هل انت متاكد من حذف هذا الخبر")) {
      try {
        await axios.delete("news/" + id);
      } catch (error) {
        console.log(error.message);
      }
      const filter = news.filter((item) => item._id !== id);
      setNews(filter);
    }
  };

  return (
    <div className="works">
      <h2 style={{ margin: "1rem 0" }}>News</h2>
      <Button mb={"lg"} onClick={() => setShowCreateAndUpdateNews(true)}>
        Create Card News
      </Button>
      {/* modal create news */}
      <Modal
        centered
        dir="rtl"
        padding={50}
        size={"xl"}
        opened={showCreateAndUpdateNews}
        onClose={() => {
          form.values.title = "";
          form.values.desc = "";
          form.values.file = "";
          setShowCreateAndUpdateNews(false);
          setUpdateNews(false);
        }}
        closeButtonLabel="xl"
      >
        <div className="addWorks">
          <form onSubmit={form.onSubmit(handelSubmit)}>
            <TextInput
              mt="lg"
              size="md"
              withAsterisk
              label="Title news"
              {...form.getInputProps("title")}
            />
            <TextInput
              mt="lg"
              size="md"
              withAsterisk
              label="عنوان الخبر"
              {...form.getInputProps("ar_title")}
            />

            <TextInput
              mt="lg"
              size="md"
              withAsterisk
              label="Descripition News"
              {...form.getInputProps("desc")}
            />

            <TextInput
              mt="lg"
              size="md"
              withAsterisk
              label="وصف الخبر "
              {...form.getInputProps("ar_desc")}
            />

            <FileInput
              withAsterisk
              mt="lg"
              size="md"
              label="Choose image news"
              icon={<FiUpload />}
              accept="image/png,image/jpeg"
              {...form.getInputProps("file")}
            />

            {updateNews ? (
              <Button loading={loading} type="submit" mt="lg">
                Update
              </Button>
            ) : (
              <Button
                loading={loading}
                loaderPosition={"center"}
                type="submit"
                mt="lg"
              >
                Create
              </Button>
            )}
          </form>
        </div>
      </Modal>

      {/* show all news */}
      <Grid>
        {news.map((item, index) => (
          <Grid.Col key={index} xs={12} sm={6} md={4} lg={3}>
            <Paper withBorder shadow={"xl"} p="10px">
              {item.imgNews && (
                <img
                  className="imgFromServer"
                  src={`${URL_IMAGES}${item.imgNews}`}
                />
              )}

              {console.log(item)}

              <Text mt={"md"} color={"#333"} className="title">
                {item.title.substring(0, 300)}
              </Text>
              <Text mt={"md"} color={"#333"} className="title">
                {item.ar_title?.substring(0, 300)}
              </Text>

              <Text mt={"md"} color={"gray"} className="desc">
                {item.desc.substring(0, 300)}
              </Text>

              <Text mt={"md"} color={"gray"} className="desc">
                {item.ar_desc?.substring(0, 300)}
              </Text>

              <div className="operations">
                <Trash
                  onClick={() => deleteSingleNews(item._id)}
                  color="gray"
                />

                <Edit2
                  color="gray"
                  className="icon"
                  onClick={() => {
                    setShowCreateAndUpdateNews(true);
                    setUpdateNews(item);
                    form.values.title = item.title;
                    form.values.ar_title = item.ar_title;
                    form.values.desc = item.desc;
                    form.values.ar_desc = item.ar_desc;
                    form.values.file = item.picture;
                  }}
                />
              </div>
            </Paper>
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
};

export default News;
