import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Table } from "@mantine/core";
import { Eye, Trash2 } from "lucide-react";
import { useContext } from "react";
import { stateContext } from "../context/FunctionContext";
const ContactUs = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const { URL_API } = useContext(stateContext);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(URL_API + "contact");
        res.data && setData(res.data);
      } catch (error) {
        console.log(error.message);
      }
    };

    getData();
  }, []);

  const handleDelete = async (element) => {
    const res = await axios.delete(URL_API + "contact/" + element._id);
    if (res.data.message === true) {
      alert("تم الحذف بنجاح");
      window.location.reload();
    }
  };
  //
  const rows = data?.map((element, i) => {
    return (
      <tr key={i}>
        <td>{element.name}</td>
        <td>{element.email}</td>
        <td>{element.phone}</td>
        <td>{new Date(element.createdAt).toLocaleDateString()}</td>
        <td style={{ display: "flex", gap: "1rem" }}>
          <Eye style={{ cursor: "pointer" }} onClick={() => setOpen(element)} />
          <Trash2
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(element)}
          />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <h2>Contact Us</h2>
      <Table
        style={{ marginTop: "2rem" }}
        striped
        highlightOnHover
        withBorder
        withColumnBorders
        horizontalSpacing="xl"
        verticalSpacing="lg"
        fontSize="sm"
      >
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Time Send</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      <Modal
        onClose={() => setOpen(false)}
        padding={"xl"}
        centered
        opened={open}
      >
        <div>Message : {open?.message}</div>
      </Modal>
    </div>
  );
};

export default ContactUs;
