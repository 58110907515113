import axios from "axios";
import { useContext } from "react";
import { stateContext } from "../context/FunctionContext";

export function useAxiosInstance() {
  const { URL_API, setIsLoading } = useContext(stateContext);

  const instance = axios.create({
    baseURL: URL_API,

    validateStatus: function (status) {
      return (
        (status >= 200 && status < 300) ||
        status === 400 ||
        status === 404 ||
        status === 401 ||
        status === 500
      );
    },
  });

  instance.interceptors.request.use(
    async (config) => {
      setIsLoading(true);
      return config;
    },
    function (error) {
      setIsLoading(false);
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use((res, err) => {
    setIsLoading(false);
    return res;
  });
  return instance;
}
