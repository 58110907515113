import { stateContext } from "../context/FunctionContext";
import { Button, TextInput, Image } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notification } from "@mantine/core";
import { Login } from "tabler-icons-react";
import logo from "../assets/logo1.png";
import z from "zod";

//
const LoginComponent = () => {
  const { setToken } = useContext(stateContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const schema = z.object({
    username: z
      .string()
      .min(4, { message: "اسم المستخدم يجب ان يكون على الاقل 4 حروف" }),

    password: z
      .string()
      .min(4, { message: "كلمة المرور يجب ان تكون على الاقل 4 حروف" }),
  });

  const form = useForm({
    initialValues: { username: "", password: "" },
    validate: zodResolver(schema),
  });

  const handleSubmit = async (e) => {
    try {
      if (
        form.values.username === "dashboard2834" &&
        form.values.password === "12341234"
      ) {
        localStorage.setItem("token", JSON.stringify(true));
        setToken(true);
        navigate("/");
      } else {
        setError("Inputs error");
      }
    } catch (error) {
      setError(error.message);
      console.log(error.message);
    }
  };

  return (
    <div className="login">
      <div className="top">
        <Image src={logo} mb="md" alt="test" height={100} />
      </div>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          size="md"
          mt="lg"
          label="Username"
          {...form.getInputProps("username")}
        />
        <TextInput
          size="md"
          type={"password"}
          mt="lg"
          label="Password"
          {...form.getInputProps("password")}
        />
        <Button
          className="button"
          leftIcon={<Login />}
          size={"lg"}
          color="defalut"
          type="submit"
        >
          Login
        </Button>

        {error && (
          <Notification color="red" mt={"sm"}>
            {error}
          </Notification>
        )}
      </form>
    </div>
  );
};

export default LoginComponent;
