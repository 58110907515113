import { Sun, MoonStars } from "tabler-icons-react";
import React, { useContext } from "react";
import { stateContext } from "../context/FunctionContext";
import logo from "../assets/logo1.png";
import { ActionIcon } from "@mantine/core";

const Header = () => {
  const { setDark, dark } = useContext(stateContext);

  return (
    <>
      <img style={{ height: "60px" }} src={logo} alt="smart path" />
      <ActionIcon
        variant="outline"
        color={dark ? "gray" : "dark"}
        onClick={() => setDark((prev) => !prev)}
        size="lg"
      >
        {dark ? <Sun size={18} /> : <MoonStars size={20} />}
      </ActionIcon>
    </>
  );
};

export default Header;
