import React, { createContext, useEffect, useState } from "react";
export const stateContext = createContext();

const FunctionContext = ({ children }) => {
  // urls
  const URL_API = "https://api.al-asriyagroup.com/api/";
  // const URL_API = "http://localhost:5000/api/";
  const URL_IMAGES = "https://api.al-asriyagroup.com/images/";
  // const URL_IMAGES = "http://localhost:5000/images/";

  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("token") || null)
  );

  // pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [openNavbar, setOpenNavbar] = useState(false);

  // change and save theme color in localStorage
  const [dark, setDark] = useState(
    localStorage.getItem("darkTheme")
      ? JSON.parse(localStorage.getItem("darkTheme"))
      : false
  );
  useEffect(() => {
    localStorage.setItem("darkTheme", JSON.stringify(dark));
  }, [dark]);

  //
  return (
    <stateContext.Provider
      value={{
        URL_API,
        URL_IMAGES,
        token,
        setToken,
        pageNumber,
        setPageNumber,
        totalPages,
        setTotalPages,
        isLoading,
        setIsLoading,
        dark,
        setDark,
        openNavbar,
        setOpenNavbar,
      }}
    >
      {children}
    </stateContext.Provider>
  );
};

export default FunctionContext;
